import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import config from './config'; // 确保路径正确

function TaskListPage() {
    const [tasks, setTasks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filterCategory, setFilterCategory] = useState('All');
    const [filterStatus, setFilterStatus] = useState('All');
    const [sortOption, setSortOption] = useState('priority');
    const [showModal, setShowModal] = useState(false);
    const [showAddTaskModal, setShowAddTaskModal] = useState(false);
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [newTask, setNewTask] = useState({
        title: '',
        description: '',
        priority: 1,
        categoryId: '',
        dueDate: ''
    });
    const [newCategory, setNewCategory] = useState('');

    useEffect(() => {
        fetchTasks();
        fetchCategories();
    }, []);

    const fetchTasks = () => {
        fetch(`${config.API_BASE_URL}/api/todos/getTasks`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => setTasks(data))
            .catch(error => console.error('Error fetching tasks:', error));
    };

    const fetchCategories = () => {
        fetch(`${config.API_BASE_URL}/api/categories/getAll`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => setCategories(data))
            .catch(error => console.error('Error fetching categories:', error));
    };


    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleCategoryChange = (e) => {
        setFilterCategory(e.target.value);
    };

    const handleStatusChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
    };

    const handleTaskClick = (task) => {
        const formattedDueDate = new Date(task.dueDate).toISOString().split('T')[0];
        setSelectedTask({ ...task, dueDate: formattedDueDate });
        setShowModal(true);
    };

    const handleSaveChanges = () => {
        if (!selectedTask) return;

        fetch(`${config.API_BASE_URL}/api/todos/${selectedTask.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                ...selectedTask,
                categoryId: parseInt(selectedTask.categoryId)
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to update task');
                }
                setTasks(tasks.map(task => task.id === selectedTask.id ? selectedTask : task));
                setShowModal(false);
                fetchTasks();
            })
            .catch(error => console.error('Error updating task:', error));
    };

    const handleAddTask = () => {
        setNewTask({
            title: '',
            description: '',
            priority: 1,
            categoryId: null,
            dueDate: ''
        });
        setShowAddTaskModal(true);
    };

    const handleAddNewTask = () => {
        fetch(`${config.API_BASE_URL}/api/todos/addTask`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(newTask)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to add new task');
                }
                return response.json();
            })
            .then(newTaskData => {
                setTasks([...tasks, newTaskData]);
                setShowAddTaskModal(false);
                setShowModal(false)
                fetchTasks();
            })
            .catch(error => console.error('Error adding new task:', error));
    };

    const handleAddCategory = () => {
        setNewCategory('')
        setShowAddCategoryModal(true);
    };

    const handleAddNewCategory = () => {
        fetch(`${config.API_BASE_URL}/api/categories/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ name: newCategory })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to add new category');
                }
                return response.json();
            })
            .then(newCategoryData => {
                setCategories([...categories, newCategoryData]);
                setShowAddCategoryModal(false);
                fetchCategories();
            })
            .catch(error => console.error('Error adding new category:', error));
    };

    const handleMarkComplete = (taskId) => {
        fetch(`${config.API_BASE_URL}/api/todos/markComplete/${taskId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to mark task as complete');
                }
                setTasks(tasks.map(task => task.id === taskId ? { ...task, completed: true } : task));
            })
            .catch(error => console.error('Error marking task complete:', error));
    };


    const handleDeleteTask = (taskId) => {
        fetch(`${config.API_BASE_URL}/api/todos/${taskId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to delete task');
                }
                setTasks(tasks.filter(task => task.id !== taskId));
            })
            .catch(error => console.error('Error deleting task:', error));
    };


    const getCardColor = (dueDate) => {
        const now = new Date();
        const due = new Date(dueDate);
        const daysLeft = (due - now) / (1000 * 60 * 60 * 24);

        if (daysLeft < 2) {
            return 'bg-light-red';
        } else if (daysLeft <= 4) {
            return 'bg-light-yellow';
        } else if (daysLeft <= 7) {
            return 'bg-light-green';
        } else {
            return 'bg-light-blue';
        }
    };


    const filteredTasks = tasks
        .filter(task => task.title.toLowerCase().includes(searchQuery.toLowerCase()))
        .filter(task => filterCategory === 'All' || task.categoryId === parseInt(filterCategory))
        .filter(task => filterStatus === 'All' || (filterStatus === 'Completed' ? task.completed : !task.completed))
        .sort((a, b) => {
            if (a.completed !== b.completed) return a.completed ? 1 : -1;
            if (sortOption === 'priority') return a.priority - b.priority;
            if (sortOption === 'dueDate') return new Date(a.dueDate) - new Date(b.dueDate);
            return 0;
        });

    return (
        <div className="container mt-5">
            {/* Dashboard Section */}
            <div className="card mb-4 shadow-sm rounded p-3">
                <h4 className="text-center text-primary mb-3">Dashboard</h4> {/* 添加 Dashboard 标题 */}
                <div className="d-flex justify-content-between align-items-center">
                    <div className="text-primary">
                        <h5 className="mb-0">Total Tasks:</h5>
                        <span className="badge bg-primary fs-5">{tasks.length}</span>
                    </div>
                    <div className="text-success">
                        <h5 className="mb-0">Completed Tasks:</h5>
                        <span className="badge bg-success fs-5">{tasks.filter(task => task.completed).length}</span>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="progress" style={{height: '20px'}}>
                        <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{width: `${(tasks.filter(task => task.completed).length / tasks.length) * 100}%`}}
                            aria-valuenow={tasks.filter(task => task.completed).length}
                            aria-valuemin="0"
                            aria-valuemax={tasks.length}
                        >
                            {tasks.length > 0
                                ? `${Math.round((tasks.filter(task => task.completed).length / tasks.length) * 100)}%`
                                : '0%'}
                        </div>
                    </div>
                </div>
            </div>


            {/* Search and Filter Section */}
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex align-items-center">
                    <label className="mr-2">Task Name:</label>
                    <input
                        type="text"
                        className="form-control mr-3"
                        placeholder="Search tasks..."
                        value={searchQuery}
                        onChange={handleSearch}
                        style={{width: '200px'}}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <label className="mr-2">Category:</label>
                    <select
                        className="form-control mr-3"
                        onChange={handleCategoryChange}
                        style={{width: '150px'}}
                    >
                        <option value="All">All</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </select>
                </div>
                <div className="d-flex align-items-center">
                    <label className="mr-2">Task Status:</label>
                    <select
                        className="form-control mr-3"
                        onChange={(e) => setFilterStatus(e.target.value)}
                        style={{width: '150px'}}
                    >
                        <option value="All">All</option>
                        <option value="Completed">Completed</option>
                        <option value="Incomplete">Incomplete</option>
                    </select>
                </div>
                <div className="d-flex align-items-center">
                    <label className="mr-2">Order:</label>
                    <select
                        className="form-control"
                        onChange={handleSortChange}
                        style={{width: '150px'}}
                    >
                        <option value="priority">Priority</option>
                        <option value="dueDate">Due Date</option>
                    </select>
                </div>


                <div className="d-flex justify-content-end mb-3">
                    <button className="btn btn-primary custom-btn mr-2" onClick={handleAddCategory}>
                        Add Category
                    </button>
                    <button className="btn btn-success custom-btn" onClick={handleAddTask}>
                        Add Task
                    </button>
                </div>

            </div>


            {/* Task List Section */}
            <div className="row">
                {filteredTasks.map(task => (
                    <div key={task.id} className="col-md-3 mb-4">
                        <div
                            className={`card h-100 task-card shadow-sm rounded border-0 ${getCardColor(task.dueDate)}`}
                            onClick={() => handleTaskClick(task)}
                            style={{
                                minHeight: '220px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div className="card-body text-left d-flex flex-column">
                                <h5 className={`card-title ${task.completed ? 'text-decoration-line-through' : ''} mb-2`}>
                                    {task.title}
                                </h5>

                                <p className="mb-2">
                                    <strong>Due Date:</strong> {new Date(task.dueDate).toLocaleDateString()}
                                </p>

                                <textarea
                                    className="form-control mb-3"
                                    readOnly
                                    value={task.description}
                                    style={{
                                        resize: 'none',
                                        height: '80px',
                                        border: 'none',
                                        boxShadow: 'none',
                                        overflow: 'hidden',
                                        backgroundColor: 'transparent'
                                    }}
                                />

                                <div className="d-flex justify-content-between mt-2">
                                    {!task.completed && (
                                        <button
                                            className="btn btn-outline-dark"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleMarkComplete(task.id);
                                            }}
                                            style={{flex: '1', marginRight: '5px'}}
                                        >
                                            Mark
                                        </button>
                                    )}
                                    <button
                                        className="btn btn-outline-danger"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteTask(task.id);
                                        }}
                                        style={{flex: '1'}}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>


            {/* Modal for Task Details and Edit */}
            {showModal && selectedTask && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Task: {selectedTask.title}</h5>
                                <button type="button" className="btn-close"
                                        onClick={() => setShowModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="editTitle">Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="editTitle"
                                            value={selectedTask.title}
                                            onChange={(e) => setSelectedTask({...selectedTask, title: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="editDescription">Description</label>
                                        <textarea
                                            className="form-control"
                                            id="editDescription"
                                            rows="3"
                                            value={selectedTask.description}
                                            onChange={(e) => setSelectedTask({
                                                ...selectedTask,
                                                description: e.target.value
                                            })}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="editPriority">Priority</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="editPriority"
                                            value={selectedTask.priority}
                                            onChange={(e) => setSelectedTask({ ...selectedTask, priority: parseInt(e.target.value) })}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="editCategory">Category</label>
                                        <select
                                            className="form-control"
                                            id="editCategory"
                                            value={selectedTask.categoryId}
                                            onChange={(e) => {
                                                const categoryId = e.target.value === 'All' ? null : parseInt(e.target.value);
                                                setSelectedTask({...selectedTask, categoryId});
                                            }}
                                        >
                                            <option value="All">All</option>
                                            {categories.map(category => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="editDueDate">Due Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="editDueDate"
                                            value={selectedTask.dueDate}
                                            onChange={(e) => setSelectedTask({
                                                ...selectedTask,
                                                dueDate: e.target.value
                                            })}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>Save
                                    Changes
                                </button>
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => setShowModal(false)}>Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Adding a New Task */}
            {showAddTaskModal && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add New Task</h5>
                                <button type="button" className="btn-close"
                                        onClick={() => setShowAddTaskModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="newTaskTitle">Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="newTaskTitle"
                                            value={newTask.title}
                                            onChange={(e) => setNewTask({...newTask, title: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="newTaskDescription">Description</label>
                                        <textarea
                                            className="form-control"
                                            id="newTaskDescription"
                                            value={newTask.description}
                                            onChange={(e) => setNewTask({...newTask, description: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="newTaskPriority">Priority</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="newTaskPriority"
                                            value={newTask.priority}
                                            onChange={(e) => setNewTask({
                                                ...newTask,
                                                priority: parseInt(e.target.value)
                                            })}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="newTaskCategory">Category</label>
                                        <select
                                            className="form-control"
                                            id="newTaskCategory"
                                            value={newTask.categoryId || 'All'}
                                            onChange={(e) => {
                                                const categoryId = e.target.value === 'All' ? null : parseInt(e.target.value);
                                                setNewTask({...newTask, categoryId});
                                            }}
                                        >
                                            <option value="All">All</option>
                                            {categories.map(category => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="newTaskDueDate">Due Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="newTaskDueDate"
                                            value={newTask.dueDate}
                                            onChange={(e) => setNewTask({...newTask, dueDate: e.target.value})}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success" onClick={handleAddNewTask}>Add Task
                                </button>
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => setShowAddTaskModal(false)}>Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {/* Modal for Adding Category */}
            {showAddCategoryModal && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add New Category</h5>
                                <button type="button" className="btn-close"
                                        onClick={() => setShowAddCategoryModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="newCategoryName">Category Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="newCategoryName"
                                            value={newCategory}
                                            onChange={(e) => setNewCategory(e.target.value)}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={handleAddNewCategory}>Add
                                    Category
                                </button>
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => setShowAddCategoryModal(false)}>Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TaskListPage;
