import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import config from "./config"

function RegisterPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }

        try {
            const response = await fetch(`${config.API_BASE_URL}/api/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            });

            const data = await response.json();

            if (!data.success) {
                throw new Error(data.message);
            }

            // alert('Registration successful');
            navigate('/');
        } catch (error) {
            alert(error.message);
            setErrorMessage(error.message);
        }
    };

    const handleReset = () => {
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setErrorMessage('');
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center mb-4 title">Register</h1>
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6">
                    <div className="card shadow-lg custom-card">
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">Create an Account</h2>
                            {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                            <form onSubmit={handleRegister}>
                                <div className="form-group d-flex align-items-center mb-3">
                                    <label htmlFor="username" className="me-3" style={{ minWidth: '120px' }}>Username:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group d-flex align-items-center mb-3">
                                    <label htmlFor="password" className="me-3" style={{ minWidth: '120px' }}>Password:</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group d-flex align-items-center mb-3">
                                    <label htmlFor="confirmPassword" className="me-3" style={{ minWidth: '120px' }}>Confirm Password:</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="d-flex justify-content-center mt-4 gap-3">
                                    <button type="button" className="btn btn-secondary" onClick={handleReset}>Reset</button>
                                    <button type="submit" className="btn btn-success">Register</button>
                                </div>
                            </form>
                            <div className="text-center mt-3">
                                <button className="btn btn-link" onClick={() => navigate('/')}>Back to Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterPage;
