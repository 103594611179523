import './App.css';

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import TaskPage from './TaskPage';
import RegisterPage from './RegisterPage';

function App() {
    console.log("Hello APP")
  return (
      <div className="App">
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/taskList" element={<TaskPage />} />
          <Route path="/register" element={<RegisterPage />} />
        </Routes>
      </div>
  );
}

export default App;